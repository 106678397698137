<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="d-block">
        <div class="d-flex align-center justify-space-between">
          <div>
            Search &amp; Filter
          </div>

          <v-btn
            color="primary"
            @click="$router.push({
              name: 'product-create-resource'
            })"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Product</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="9"
          class="d-flex align-center"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="natureFilter"
            placeholder="Nature"
            :items="natureOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="categoryFilter"
            placeholder="Category"
            :items="categoriesOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="tagFilter"
            placeholder="Tag"
            :items="tagsOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="merchantFilter"
            placeholder="Merchant"
            :items="merchantOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="channelNoteFilter"
            placeholder="Channel Note"
            :items="channelNoteOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>

          <v-text-field
            v-model="minSkuPriceFromFilter"
            label="Price From"
            class="ml-2"
          ></v-text-field>

          <v-text-field
            v-model="minSkuPriceToFilter"
            label="Price To"
            class="ml-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-end align-center flex-wrap">
        <template
          v-if="selectedRows.length"
        >
          <p class="mr-2">
            Bulk Actions:
          </p>
          <v-btn
            color="success"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllProducts({ active: 1 })"
          >
            <span>Activate</span>
          </v-btn>
          <v-btn
            color="warning"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllProducts({ active: 0 })"
          >
            <span>Deactive</span>
          </v-btn>
        </template>
      </div>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="productListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- Image -->
        <template #[`item.media`]="{item}">
          <main-media :media="item.media" />
        </template>

        <!-- ID -->
        <template #[`item.product_number`]="{item}">
          <div
            style="min-width: 120px;"
          >
            {{ item.product_number }}
            <small v-if="item.merchant_product_id">({{ item.merchant_product_id }}) </small>
          </div>
        </template>

        <!-- Name -->
        <template #[`item.product`]="{item}">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'product-view', params: { id : item.id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <nature-icon
                v-show="!natureFilter"
                class="mr-2"
                :nature="item.nature"
              />
              {{ t(item.title) }}
            </router-link>
          </div>
        </template>

        <!-- Price -->
        <template #[`item.max_sku_price`]="{item}">
          <div
            class="d-flex align-center"
            style="min-width: 200px;"
          >
            {{ item.reference_price.min_sku_price | localPrice({ currency: item.currency }) }} - {{ item.reference_price.max_sku_price | localPrice({ currency: item.currency }) }}
          </div>
        </template>

        <!-- Category -->
        <template #[`item.category`]="{item}">
          <!-- <p>{{ rootCategoryName(item.categories) }}</p> -->
          <v-chip
            v-for="cat in item.categories"
            :key="cat.id"
          >
            {{ t(cat.name) }}
          </v-chip>
        </template>

        <!-- Merchant -->
        <template #[`item.merchant`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="'primary'"
              :class="'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span
                class="font-weight-medium"
              >{{ avatarText(item.merchant.name.en) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ t(item.merchant.name) }}
              </span>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status !== 'unpublished'"
            small
            :color="item.active ? 'success' : ''"
            :class="`${item.active && item.status === 'published' ? 'success' : ''}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- tag -->
        <template #[`item.tag`]="{item}">
          <v-chip
            v-for="tag in item.tags"
            :key="tag.id"
          >
            {{ t(tag.name) }}
          </v-chip>
        </template>

        <!-- updated at -->
        <template #[`item.updated_at`]="{item}">
          <div
            style="min-width: 100px;"
          >
            <span>{{ item.updated_at | date }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'product-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="replicateProduct(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Replicate</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'draft'"
                link
                @click="confirmTrashProduct(item).then(loadProducts)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Trash</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'published'"
                link
                @click="unpublishProduct(item.id).then(loadProducts)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                  </v-icon>
                  <span>Unpublish</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiAccountOutline,
mdiContentCopy,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import channelStore from '@/modules/channel/store'
import appStore from '@/modules/common/store'
import merchantStore from '@/modules/merchant/store'
import store from '@/modules/product/store'
import { avatarText, date, localPrice } from '@core/utils/filter'

import { MainMedia, NatureIcon } from '@/components'
import { useNotifySuccess } from '@/composables'
import { t } from '@/plugins/i18n'
import { computed } from '@vue/composition-api'
import useProduct from '../useProduct'
import useProductList from './useProductList'

export default {
  components: { NatureIcon, MainMedia },
  filters: { date, localPrice },
  setup() {
    const {
      productListTable,
      tableColumns,
      searchQuery,
      natureFilter,
      categoryFilter,
      statusFilter,
      tagFilter,
      merchantFilter,
      minSkuPriceFromFilter,
      minSkuPriceToFilter,
      channelNoteFilter,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadProducts,
      updateAllProducts,
      resolveStatusVariant,
      resolveUserTotalIcon,
    } = useProductList()

    const { unpublishProduct, confirmTrashProduct, confirmReplicate } = useProduct()

    const statusOptions = [
      { title: 'Draft', value: 'draft' },
      { title: 'Published', value: 'published' },
      { title: 'Unpublished', value: 'unpublished' },
    ]

    const rootCategoryName = cats => {
      const cat = cats.find(c => !c.parent_id)

      return cat ? t(cat.name) : ''
    }

    const categoriesOptions = computed(() => appStore.state.categories.map(cat => ({ title: `${t(cat.name)} (${cat.code})`, value: cat.id })))
    const tagsOptions = computed(() => appStore.state.tags.filter(tag => tag.type === 'product_status').map(tag => ({ title: `${t(tag.name)}`, value: tag.id })))

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `${t(merchant.name)} (${merchant.code})`, value: merchant.id })))

    const channelNoteOptions = computed(() => channelStore.state.notes.map(note => ({ title: `${t(note.name)} (${note.channel.code})`, value: note.key })))

    const replicateProduct = async product => {
      await confirmReplicate(product)
      loadProducts()
      useNotifySuccess({ content: 'Replication succeeded' })
    }

    return {
      productListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      natureFilter,
      categoryFilter,
      tagFilter,
      merchantFilter,
      minSkuPriceFromFilter,
      minSkuPriceToFilter,
      channelNoteFilter,
      totalListTable,
      statusOptions,
      natureOptions: store.state.natureOptions,
      categoriesOptions,
      tagsOptions,
      merchantOptions,
      channelNoteOptions,
      loading,
      options,
      totalLocal,
      selectedRows,
      avatarText,
      resolveStatusVariant,
      resolveUserTotalIcon,
      loadProducts,
      updateAllProducts,
      unpublishProduct,
      confirmTrashProduct,
      rootCategoryName,

      t,
      replicateProduct,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiContentCopy,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
